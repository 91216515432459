// @ts-check

/**
 * @type {import('next-i18next').UserConfig}
 */

module.exports = {
  i18n: {
    defaultLocale: 'en',
    interpolation: {
      escapeValue: false,
    },
    locales: ['en', 'fr', 'de', 'es', 'it', 'pt'],
  },
  fallbackLng: {
    default: ['en'],
  },
  debug: false,
  reloadOnPrerender: process.env.NODE_ENV === 'development',
  localePath:
    typeof window === 'undefined'
      ? require('path').resolve('./public/locales')
      : 'common/locales',
}
